<template>
  <div>
    <ConsumerModal
      :show="showConsumerModal"
      :onClose="handleConsumerClose"
      :onContinue="handleContinue"
    />
    <b-modal
      size="xl"
      :title="title"
      v-model="showModal"
      @hidden="() => onClose()"
      hide-footer
    >
      <BrowserContainer>
        <ConsumerInterface
          :preview="true"
          :previewClientID="selectedConsumer.client_id"
          :previewConsumerID="selectedConsumer.consumer_id"
          :task="task"
          :consumer="selectedConsumer"
        />
      </BrowserContainer>
    </b-modal>
  </div>
</template>

<script>
import BrowserContainer from "@/components/templates/BrowserContainer";
import ConsumerInterface from "@/consumer_ui/ConsumerInterface";
import ConsumerModal from "@/components/shared/ConsumerModal";

export default {
  data() {
    return {
      showModal: false,
      title: "Task Preview",
      showConsumerModal: this.show,
      selectedConsumer: {},
    };
  },
  props: ["show", "task", "onClose"],
  components: {
    BrowserContainer,
    ConsumerInterface,
    ConsumerModal,
  },
  computed: {
    widgets() {
      return this.task?.widgetMeta || [];
    },
  },
  methods: {
    handleConsumerClose() {
      if (!this.selectedConsumer?.consumer_email) {
        this.showConsumerModal = false;
        this.showModal = false;
      } else {
        this.showConsumerModal = false;
      }

      this.onClose();
    },
    handleContinue(consumer) {
      this.selectedConsumer = consumer;
      this.showConsumerModal = false;
      this.showModal = true;
    },
  },
  watch: {
    show(val) {
      this.showConsumerModal = val;
    },
  },
};
</script>
