<template>
  <div class="widget-container">
    <div class="widget-title row mt-4">
      <div class="col-sm-3">
        <h6>Title</h6>
      </div>
      <div class="col-sm-9">
        <input type="text" v-model="name" />
      </div>
    </div>
    <div class="widget-title mt-4">
      <textarea type="text" v-model="message" placeholder=" Add a message..." />
    </div>
    <hr />
    <div class="text-col">
      <label :for="'file-upload' + name">{{ message }}</label>
      <b-form-file
        :id="'file-upload' + name"
        :name="name"
        accept=".png, .txt, .doc, .docx, .pdf"
        size="sm"
        variant="light"
        disabled
      ></b-form-file>
    </div>
    <div class="w-100 my-3 d-flex justify-content-around">
      <button class="btn-outline-blue w-25" @click.prevent="cancelEdit">
        Close
      </button>
      <button class="btn-blue w-25" @click.prevent="submitFileupload">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { Storage } from "aws-amplify";

export default {
  props: ["widgetMeta"],
  data() {
    return {
      name: "",
      message: "",
    };
  },
  mounted() {
    if (this.widgetMeta && typeof this.widgetMeta == "object") {
      if (this.widgetMeta.content) {
        this.message = this.widgetMeta.content.message || "";
      } else {
        this.message = "";
      }
      this.name = this.widgetMeta.name || "";
    }
  },
  methods: {
    async submitFileupload() {
      if (!this.message) {
        return this.$store.dispatch(
          "createErrors",
          "Please add a message for this file upload."
        );
      }
      if (!this.name) {
        return this.$store.dispatch(
          "createErrors",
          "Please add a name for this file upload."
        );
      }

      const data = {
        content: {
          message: this.message,
        },
        type: "upload",
        name: this.name,
      };
      this.$bus.$emit("widgetSaved", data);
    },
    cancelEdit() {
      this.$bus.$emit("clearWidget");
    },
  },
};
</script>
