<template>
  <div class="widget-container">
    <div class="form form-horizontal pl-1 pr-1">
      <div class="form-group mb-2">
        <div class="form-row">
          <label class="col-sm-4 text-left">
            Title
          </label>
          <div class="col-sm-8">
            <input
              class="form-control form-control-sm"
              type="text"
              v-model="name"
            />
          </div>
        </div>
      </div>
      <div class="form-group mb-2">
        <div class="form-row">
          <label class="col-sm-4 text-left">
            Video URL
          </label>
          <div class="col-sm-8">
            <input
              class="form-control form-control-sm"
              type="text"
              v-model="video"
            />
          </div>
        </div>
        <div class="form-row" v-if="video.length > 0">
          <vue-plyr>
            <div class="plyr__video-embed">
              <iframe
                :src="video"
                allowfullscreen
                allowtransparency
                allow="autoplay"
              ></iframe>
            </div>
          </vue-plyr>
        </div>
      </div>
    </div>
    <div class="w-100 mt-2 d-flex justify-content-around">
      <button
        class="btn-outline-blue w-25"
        @click.prevent="cancelEdit"
        v-if="widgetMeta != null"
      >
        Close
      </button>
      <button
        class="btn-blue w-25"
        @click.prevent="submitVideo"
        v-if="widgetMeta != null"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { Storage } from "aws-amplify";
import Vue from "vue";
import VuePlayer from "../../VuePlayer";
Vue.use(VuePlayer);

export default {
  props: ["widgetMeta"],
  data() {
    return {
      name: "",
      video: "",
    };
  },
  mounted() {
    if (this.widgetMeta && this.widgetMeta != []) {
      if (this.widgetMeta.content) {
        this.video = this.widgetMeta.content;
      }
      if (this.widgetMeta.name) {
        this.name = this.widgetMeta.name;
      }
    }
  },
  methods: {
    async submitVideo() {
      const data = {
        content: this.video,
        type: "video",
        name: this.name,
      };
      this.$bus.$emit("widgetSaved", data);
    },
    cancelEdit() {
      this.$bus.$emit("clearWidget");
    },
  },
};
</script>
