<template>
  <div class="widget-container">
    <div class="form form-horizontal pl-1 pr-1">
      <div class="form-group mb-2">
        <div class="form-row">
          <label class="col-sm-4 text-left">
            Title:
          </label>
          <div class="col-sm-8">
            <input
              class="form-control form-control-sm"
              type="text"
              v-model="name"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="form-group mb-2">
        <div class="form-row">
          <label class="col-sm-4 text-left">
            Upload your file:
          </label>
          <div class="col-sm-8">
            <b-form-file
              class="form-control form-control-sm"
              @change="onChange($event)"
              accept="image/*, .txt, .doc, .docx, .pdf"
              size="sm"
              id="file-small"
            ></b-form-file>
          </div>
        </div>
      </div>
      <div class="form-group mb-2">
        <div class="form-row">
          <label class="col-sm-4 text-left">
            Header Message:
          </label>
          <div class="col-sm-8 row">
            <div class="col-12">
              <textarea
                class="form-control form-control-sm"
                v-model="message"
                placeholder="Watch This!"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="download-widget mt-4" v-if="preview != null">
      <div class="download-row">
        <strong class="strong">Message:</strong>
        <p>{{ message }}</p>
      </div>
      <div class="download-row">
        <strong class="strong">File:</strong>
        <a class="text-left" :href="preview"
          ><i class="fas fa-download"></i> {{ fileName }}</a
        >
      </div>
    </div>
    <div class="w-100 mt-2 d-flex justify-content-around py-2">
      <button
        class="btn-outline-blue w-25"
        @click.prevent="cancelEdit"
        v-if="preview !== '' || widgetMeta !== null"
      >
        Close
      </button>
      <button
        class="btn-blue w-25"
        @click.prevent="uploadFiles"
        v-if="preview !== '' || widgetMeta !== null"
      >
        <p v-if="!submitting" class="m-0 p-0">Save</p>
        <div class="text-center" v-else>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["widgetMeta"],
  data() {
    return {
      file: null,
      name: "",
      preview: null,
      message: "",
      fileName: null,
    };
  },
  computed: { ...mapGetters(["getClientInContext"]) },
  mounted() {
    if (this.widgetMeta && this.widgetMeta != []) {
      if (this.widgetMeta.content) {
        this.preview = this.widgetMeta.content.url;
        this.fileName = this.widgetMeta.content.fileName;
        this.message = this.widgetMeta.content.message || "";
      }
      if (this.widgetMeta.name) {
        this.name = this.widgetMeta.name;
      }
    }
  },
  methods: {
    async onChange(e) {
      this.file = (await e.target.files[0]) || e.dataTransfer.files[0];
    },
    async uploadFiles() {
      const data = {
        content: {},
        type: "download",
        name: "File Download",
      };

      if (!this.widgetMeta && !this.file) {
        return this.$store.dispatch(
          "createErrors",
          "Please upload a file for download."
        );
      }

      if (this.widgetMeta != null && this.widgetMeta.content != null) {
        data.content.fileName = this.widgetMeta.content.fileName || null;
        data.content.url = this.widgetMeta.content.url || null;
        data.name = this.widgetMeta.name;
      }

      let url = null;

      if (this.file != null) {
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("clientID", this.getClientInContext.client_id);
        formData.append("fileName", this.file.name.trim());
        if (this.name) {
          formData.append("widgetName", this.name.trim());
        }
        url = await this.$store.dispatch("uploadFile", {
          clientID: this.getClientInContext.client_id,
          formData: formData,
        });

        if (!url) {
          return this.$store.dispatch(
            "createErrors",
            "There was an issue uploading your file, please try again."
          );
        }

        data.content.url = url;
        data.content.fileName = this.file.name;
      }

      if (this.message) {
        data.content.message = this.message;
      }

      if (this.name) {
        data.name = this.name;
      }

      // const data = {
      // 	content: {
      // 		url: url,
      // 		fileName: this.file.name,
      // 		message: this.message || this.widgetMeta.content.message || ''
      // 	},
      // 	type: 'download',
      // 	name: this.name || this.widgetMeta.name || null
      // };
      this.$bus.$emit("widgetSaved", data);
    },
    cancelEdit() {
      this.$bus.$emit("clearWidget");
    },
  },
};
</script>
