<template>
  <div class="widget-container">
    <div class="form form-horizontal pl-1 pr-1">
      <div class="form-group mb-2">
        <div class="form-row">
          <label class="col-sm-4 text-left">
            Title
          </label>
          <div class="col-sm-8">
            <input
              class="form-control form-control-sm"
              type="text"
              v-model="name"
            />
          </div>
        </div>
      </div>
      <div class="form-group mb-2">
        <div class="text-widget">
          <div v-if="showDataBag" class="databag mb-3 mt-2">
            <b-form-select
              :options="options"
              class="b-select"
              v-model="data"
            ></b-form-select>
            <button class="btn-blue" @click="insertItem(data)">Add</button>
          </div>
          <div id="toolbar">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
            <button class="ql-blockquote"></button>
            <select class="ql-color">
              <option value="rgb(0, 0, 0)" />
              <option value="rgb(230, 0, 0)" />
              <option value="rgb(255, 153, 0)" />
              <option value="rgb(255, 255, 0)" />
              <option value="rgb(0, 138, 0)" />
              <option value="rgb(0, 102, 204)" />
              <option value="rgb(153, 51, 255)" />
              <option value="rgb(255, 255, 255)" />
              <option value="rgb(250, 204, 204)" />
              <option value="rgb(255, 235, 204)" />
              <option value="rgb(204, 224, 245)" />
              <option value="rgb(235, 214, 255)" />
              <option value="rgb(187, 187, 187)" />
              <option value="rgb(102, 185, 102)" />
            </select>
            <!--<select class="ql-align"></select>-->
            <button class="ql-link"></button>
            <button id="custom-button" @click="showDataBag = true">
              <i class="fas fa-user-plus"></i>
            </button>
          </div>
          <div id="editor"></div>
        </div>
      </div>

      <div v-if="preview != ''">
        <p class="text-border mt-3"><strong>Preview of your text:</strong></p>
        <div v-html="preview" class="mt-4"></div>
      </div>
      <div class="w-100 mt-2 d-flex justify-content-around">
        <button
          class="btn-outline-blue w-25"
          @click.prevent="cancelEdit()"
          v-if="preview != '' || widgetMeta != null"
        >
          Close
        </button>
        <button
          class="btn-blue w-25"
          @click.prevent="submitText()"
          v-if="preview != '' || widgetMeta != null"
        >
          Save Text
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Quill from "quill";

export default {
  props: ["widgetMeta"],
  data() {
    return {
      quill: null,
      preview: "",
      name: "",
      data: null,
      showDataBag: false,
      options: [
        { value: null, text: "Please select an available attribute" },
        { value: "consumer_email", text: "Email" },
      ],
    };
  },
  async mounted() {
    this.quill = new Quill("#editor", {
      theme: "snow",
      modules: {
        toolbar: "#toolbar",
      },
    });
    let self = this;
    this.quill.on("text-change", (delta, oldDelta, source) => {
      this.preview = self.quill.root.innerHTML;
    });
    if (this.widgetMeta && this.widgetMeta != []) {
      if (this.widgetMeta.content) {
        this.quill.setContents(this.widgetMeta.content.quill);
      }
      if (this.widgetMeta.name) {
        this.name = this.widgetMeta.name;
      }
    }

    if (this.getClientInContext) {
      let clientData = await this.$store.dispatch(
        "getDataBagAttributes",
        this.getClientInContext.client_id
      );
      if (clientData && clientData.consumer_meta) {
        let keys = Object.keys(clientData.consumer_meta);
        for (let i in keys) {
          this.options.push({
            value: keys[i],
            text: clientData.consumer_meta[keys[i]].name || keys[i],
          });
        }
      }
    }
  },
  computed: { ...mapGetters(["getClientInContext"]) },
  methods: {
    insertItem(data) {
      const item = " {{ consumer." + data + " }} ";
      let selection = this.quill.getSelection();
      let idx = selection != null ? selection.index : 0;
      this.quill.insertText(idx, item);
      this.showDataBag = false;
    },
    submitText() {
      const content = this.quill.getContents();
      const data = {
        content: {
          html: this.preview,
          quill: content,
        },
        type: "text",
        name: this.name,
      };
      this.$bus.$emit("widgetSaved", data);
    },
    cancelEdit() {
      this.$bus.$emit("clearWidget");
    },
  },
};
</script>
