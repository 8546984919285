<template>
	<div>
		<b-navbar class="task-container" type="light">
			<b-collapse is-nav>
				<b-navbar-nav>
					<b-nav-item-dropdown text="Output">
						<b-dropdown-item v-for="widget in outputWidgets" :key="widget.id" class="dropdown-item" href="javascript:void(0)" @click="onClick(widget)"><i :class="widget.icon"></i>&nbsp;{{ widget.text }}</b-dropdown-item>
					</b-nav-item-dropdown>
				</b-navbar-nav>
				<b-navbar-nav>
					<b-nav-item-dropdown text="Input">
						<b-dropdown-item v-for="widget in inputWidgets" :key="widget.id" class="dropdown-item" href="javascript:void(0)" @click="onClick(widget)"><i :class="widget.icon"></i>&nbsp;{{ widget.text }}</b-dropdown-item>
					</b-nav-item-dropdown>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
	</div>
</template>

<script>

export default {
  data() {
    return {
			element: '',
			globalID: 0,
			outputWidgets: [
				{ text: 'Text', icon: 'fas fa-font', type: 'TextEdit'},
				{ text: 'Photo', icon: 'far fa-image', type: 'Photo'},
				{ text: 'Video', icon: 'fas fa-video', type: 'Video'},
				{ text: 'Add To My Calendar', icon: 'far fa-calendar-plus', type: 'Calendar'},
				{ text: 'File Download', icon: 'fas fa-download', type: 'Download'},
			],
			inputWidgets : [
				{ text: 'Questionnaire', icon: 'fas fa-clipboard-list', type: 'Questionnaire'},
				{ text: 'File Upload', icon: 'fas fa-file-upload', type: 'Upload' },
			]
		};
  },
	methods : {
		onChange(e) {
			this.element = e.draggedContext.element;
		},
		onClick(widget) {
			this.element = {
				id: this.globalID++,
				widget: {
					name: '',
					text: widget.text,
					type: widget.type
				}
			};
			this.$bus.$emit('addWidget', this.element);
		},
		onClone(element) {
			this.element = element;
			return {
				id: this.globalID++,
				widget: {
					name: '',
					text: this.element.text,
					type: this.element.type
				}
			};
		}
	}
};
</script>
