<template>
  <div class="widget-container">
    <div class="form form-horizontal pl-1 pr-1">
      <div class="form-group mb-2">
        <div class="form-row">
          <label class="col-sm-4 pt-2 text-left">
            Title:
          </label>
          <div class="col-sm-8">
            <input
              class="form-control form-control-sm"
              type="text"
              v-model="invite.title"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="form-group mb-2">
        <small class="text-muted mb-2">
          Dates may be pulled from Consumer profiles only if there are date
          fields defined in Consumer Fields. (hyperlink to /consumers/metadata).
          If 'A fixed date' is chosen, display the datepicker. If 'Consumer
          Profile' is chosen, display the select of date/datetime consumer
          metadata columns
        </small>
        <div class="form-row">
          <label class="col-sm-4 pt-2 text-left">
            Pull Date From:
          </label>
          <div class="col-sm-8">
            <b-form-select
              class="form-control form-control-sm"
              v-model="datePullSelection"
              :options="datePullOptions"
            ></b-form-select>
          </div>
        </div>
      </div>
      <template v-if="datePullSelection">
        <div class="form-group mb-2" v-if="datePullSelection == 'profile'">
          <div class="form-row">
            <label class="col-sm-4 pt-2 text-left">
              Consumer Date Options:
            </label>
            <div class="col-sm-8">
              <template
                v-if="
                  Array.isArray(consumer_meta_datetime_options) &&
                    consumer_meta_datetime_options.length > 1
                "
              >
                <b-form-select
                  class="form-control form-control-sm"
                  v-model="invite.consumerDateTime"
                  :options="consumer_meta_datetime_options"
                  @change="onDateTimeMetaSelected()"
                ></b-form-select>
              </template>
              <template v-else>
                There are no consumer fields with a date/date + time value. An
                administrator may add one to your account via the consumers
                page.
              </template>
            </div>
          </div>
        </div>
        <div class="form-group mt-2 mb-2" v-if="datePullSelection == 'fixed'">
          <!-- manual entry date + time options -->
          <div class="form-row">
            <label class="col-sm-4 text-left"> </label>
            <div class="col-sm-8">
              <b-form-datepicker
                class="form-control form-control-sm time-picker px-1"
                size="sm"
                locale="en"
                v-model="invite.date"
              ></b-form-datepicker>
            </div>
          </div>
          <div class="form-row">
            <label class="col-sm-4 text-left"> </label>
            <div class="col-sm-8">
              <b-form-timepicker
                class="form-control form-control-sm time-picker px-1"
                size="sm"
                placeholder="Start Time"
                locale="en"
                v-model="invite.time_start"
              >
              </b-form-timepicker>
            </div>
          </div>
        </div>
        <div class="form-group mt-2 mb-2" v-if="!hideTime">
          <div class="form-row">
            <label class="col-sm-4 text-left">
              Duration (minutes)
            </label>
            <div class="col-sm-8">
              <input
                class="form-control form-control-sm"
                type="number"
                placeholder="Duration (minutes)"
                v-model="invite.duration"
              />
            </div>
          </div>
        </div>
        <div class="form-group mt-2 mb-2">
          <div class="form-row">
            <label class="col-sm-4 text-left">
              Location
            </label>
            <div class="col-sm-8">
              <input
                class="form-control form-control-sm"
                type="text"
                placeholder="Location of event"
                v-model="invite.location"
              />
            </div>
          </div>
        </div>
        <div class="form-group mt-2 mb-2">
          <small class="text-muted mb-2">
            By default a hyperlink is generated that allows a user to add this
            event to their own calendar. If you add invitees using this section,
            then by adding this event to their calendar, those invitees will
            also be sent a copy of the event by email.
          </small>
          <div class="form-row">
            <label class="col-sm-4 pt-2 text-left">
              Invite People
            </label>
            <div class="col-sm-8 row">
              <div
                class="col-12 row mb-2"
                v-for="(invitee, i) in invite.invitees"
                :key="'invitees' + i"
              >
                <div class="col-11">
                  <div class="col-12 row">
                    <b-form-select
                      class="form-control"
                      size="sm"
                      v-model="inviteeOptionSelect[i]"
                      :options="inviteeOptions"
                    ></b-form-select>
                  </div>
                  <div class="col-12 row">
                    <template v-if="inviteeOptionSelect[i] == 'consumer'">
                      <input
                        class="form-control form-control-sm"
                        type="email"
                        readonly=""
                        disabled=""
                        value="<consumer@example.com>"
                      />
                    </template>
                    <template v-else-if="inviteeOptionSelect[i] == 'manager'">
                      <b-form-select
                        class="form-control"
                        size="sm"
                        v-model="invite.invitees[i]"
                        :options="managerInviteeOptions"
                      ></b-form-select>
                    </template>
                    <template v-else>
                      <input
                        class="form-control form-control-sm"
                        type="email"
                        v-model="invite.invitees[i]"
                        placeholder="person@custom_email.com"
                      />
                    </template>
                  </div>
                </div>
                <div class="col-1">
                  <a @click="removeInvitee(i)" class="text-danger">&times;</a>
                </div>
              </div>
              <div class="col-12 row">
                <a href="javascript:void(0)" @click="addInvitee">
                  <i class="fas fa-plus col-sm-1"></i>&nbsp;Add Invitee
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group mb-2 mt-3">
          <div class="form-row">
            <label class="col-sm-4 text-left">
              Invitee Message
            </label>
            <div class="col-sm-8">
              <div class="col-12 row">
                <textarea
                  class="form-control form-control-sm"
                  v-model="invite.message"
                  placeholder="You have been invited!..."
                  type="text"
                />
              </div>
              <small class="col-12 row text-muted text-left">
                Dynamic variables may be added to your message.
              </small>
              <div class="col-12 row">
                <label class="col-sm-4 pl-0 pt-2 text-left">
                  Insert Variable:
                </label>
                <div class="col-sm-8">
                  <b-form-select
                    class="form-control"
                    size="sm"
                    v-model="meta_option"
                    :options="consumer_meta_options"
                    @change="addMeta()"
                  ></b-form-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 mt-2 d-flex justify-content-around py-2">
          <button class="btn-outline-blue w-25" @click.prevent="cancelEdit">
            Close
          </button>
          <button class="btn-blue w-25" @click.prevent="createInvite">
            Save
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Storage } from "aws-amplify";
import { google } from "calendar-link";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: ["widgetMeta"],
  data() {
    return {
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      name: "",
      invite: {
        title: "",
        date: null,
        time_start: null,
        duration: "",
        consumerDateTime: null,
        invitees: [],
        location: "",
        message: "",
      },
      hideTime: false,
      inviteeOptionSelect: [],
      inviteeOptions: [
        { value: "consumer", text: "The Consumer <consumer@example.com>" },
        { value: "manager", text: "A Manager's Email <user@example.com>" },
        { value: "custom", text: "Another Person" },
      ],
      datePullSelection: "fixed",
      datePullOptions: [
        { value: "fixed", text: "A fixed date (e.g. for a live event)" },
        { value: "profile", text: "Consumer Profile" },
      ],
      managerInviteeOptions: [{ value: "", text: "Please select an email" }],
      meta_option: null,
      consumer_meta_options: [{ value: null, text: "Select Variable" }],
      consumer_meta_datetime_options: [
        { value: null, text: "Tie calendar invite to consumer data" },
      ],
    };
  },
  async mounted() {
    let userData;
    try {
      userData = await this.$store.dispatch("getClientUsers");
    } catch (err) {
      // pass
    }
    this.managerInviteeOptions = [];
    if (userData && Array.isArray(userData) && userData.length > 0) {
      this.managerInviteeOptions = [{ value: "", text: "Manager Emails" }];
      for (let i = 0; i < userData.length; i++) {
        if (userData[i].email) {
          this.managerInviteeOptions.push({
            value: userData[i].email,
            text: `${userData[i].email}`,
          });
        }
      }
    }

    if (this.getClientInContext) {
      let options = await this.$store.dispatch(
        "getDataBagAttributes",
        this.getClientInContext.client_id
      );
      if (options && options.consumer_meta) {
        for (let i in options.consumer_meta) {
          if (options.consumer_meta[i].type == "date") {
            this.consumer_meta_datetime_options.push({
              value: { type: "date", value: `{{ consumer.${i} }}` },
              text: `${i} (All Day Event)`,
            });
          } else if (options.consumer_meta[i].type == "date + time") {
            this.consumer_meta_datetime_options.push({
              value: { type: "date + time", value: `{{ consumer.${i} }}` },
              text: `${i} (Event With Set Duration)`,
            });
          }
          this.consumer_meta_options.push({ value: i, text: i });
        }
      }
    }

    if (
      this.widgetMeta != null &&
      this.widgetMeta != [] &&
      this.widgetMeta.content != null
    ) {
      const {
        title,
        date,
        time_start,
        duration,
        invitees,
        location,
        message,
        consumerDateTime,
      } = this.widgetMeta.content.invite;
      [
        this.invite.title,
        this.invite.date,
        this.invite.time_start,
        this.invite.duration,
        this.invite.location,
        this.invite.message,
        this.invite.consumerDateTime,
      ] = [
        title,
        date,
        time_start,
        duration,
        location,
        message,
        consumerDateTime,
      ];
      if (this.invite.consumerDateTime != null) {
        this.onDateTimeMetaSelected();
      }
      if (date != null) {
        if (date.startsWith("{{") && new Date(date) == "Invalid Date") {
          this.datePullSelection = "profile";
        }
      }
      this.invite.invitees = [];
      this.inviteeOptionSelect = [];
      if (Array.isArray(invitees) && invitees.length > 0) {
        for (let i in invitees) {
          if (invitees[i] == "{{ consumer.consumer_email }}") {
            this.invite.invitees.push("");
            this.inviteeOptionSelect.push("consumer");
          } else if (
            this.managerInviteeOptions.find((element) => {
              return element.value.toLowerCase() == invitees[i].toLowerCase();
            })
          ) {
            this.invite.invitees.push(invitees[i]);
            this.inviteeOptionSelect.push("manager");
          } else {
            this.invite.invitees.push(invitees[i]);
            this.inviteeOptionSelect.push("custom");
          }
        }
      }
    }
  },
  computed: { ...mapGetters(["getClientInContext"]) },
  methods: {
    addInvitee() {
      this.invite.invitees.push("");
      this.inviteeOptionSelect.push("consumer");
    },
    removeInvitee(i) {
      this.invite.invitees.splice(i, 1);
    },
    onDateTimeMetaSelected() {
      if (this.invite.consumerDateTime != null) {
        this.invite.date = this.invite.consumerDateTime.value;
        this.invite.time_start = this.invite.consumerDateTime.value;
        if (this.invite.consumerDateTime.type == "date") {
          this.invite.duration = 1439;
          return (this.hideTime = true);
        }
      }
      return (this.hideTime = false);
    },
    addMeta() {
      if (this.meta_option) {
        this.invite.message =
          this.invite.message + `{{ consumer.${this.meta_option} }}`;
      }
      this.meta_option = null;
    },
    createInvite() {
      let {
        title,
        date,
        time_start,
        duration,
        invitees,
        location,
        message,
        consumerDateTime,
      } = this.invite;

      if (
        moment(date, "YYYY-MM-DD", true).isValid() &&
        moment(time_start, "HH:mm:ss", true).isValid()
      ) {
        this.invite.time_start = moment(
          date + "" + time_start,
          "YYYY-MM-DDHH:mm:ss"
        ).format("YYYY-MM-DDTHH:mm:ssz");
        this.invite.date = moment(
          date + "" + time_start,
          "YYYY-MM-DDHH:mm:ss"
        ).format("YYYY-MM-DDTHH:mm:ssz");
      }

      let errors = [];

      if (!title) {
        errors.push("Please enter a title for your invite.");
      }
      if (!date && !time_start && !duration) {
        errors.push(
          "Please enter a date, start time, and duration for this invite."
        );
      }
      if (!location) {
        errors.push("Please add a location.");
      }

      // process & parse invitees
      if (
        errors.length == 0 &&
        Array.isArray(invitees) &&
        invitees.length > 0
      ) {
        let hasConsumer = false;
        let processedManagers = [];
        let processedCustom = [];
        for (let i in this.inviteeOptionSelect) {
          if (this.inviteeOptionSelect[i] == "consumer") {
            hasConsumer = true;
          } else if (this.inviteeOptionSelect[i] == "manager") {
            if (!invitees[i]) {
              errors.push(
                "Invitee #" +
                  (i + 1) +
                  " is invalid, please select a manager or remove the entry."
              );
            }
            processedManagers.push(invitees[i]);
          } else {
            // custom emails
            if (!this.emailRegex.test(invitees[i].trim())) {
              errors.push(
                "Invitee #" +
                  (i + 1) +
                  " is not a valid email, only valid emails can be invitees"
              );
            }
            processedCustom.push(invitees[i].trim());
          }
        }
        if (errors.length == 0) {
          let filteredInviteeOptions = [];
          let processedInvitees = [];
          if (hasConsumer) {
            processedInvitees.push("{{ consumer.consumer_email }}");
            filteredInviteeOptions.push("consumer");
          }
          if (processedManagers.length > 0) {
            processedManagers = processedManagers.filter(
              (value, index, self) => {
                return self.indexOf(value) === index;
              }
            );
            filteredInviteeOptions.push(
              ...Array(processedManagers.length).fill("manager")
            );
            processedInvitees.push(...processedManagers);
          }
          if (processedCustom.length > 0) {
            processedCustom = processedCustom.filter((value, index, self) => {
              return self.indexOf(value) === index;
            });
            filteredInviteeOptions.push(
              ...Array(processedCustom.length).fill("custom")
            );
            processedInvitees.push(...processedCustom);
          }
          this.invite.invitees = processedInvitees;
          this.inviteeOptionSelect = filteredInviteeOptions;
        }
      }

      if (errors.length > 0) {
        return this.$store.dispatch("createErrors", errors);
      }

      const data = {
        content: { invite: this.invite, url: "#" },
        type: "calendar",
        name: this.invite.title,
      };

      return this.$bus.$emit("widgetSaved", data);
    },
    cancelEdit() {
      this.$bus.$emit("clearWidget");
    },
  },
};
</script>
