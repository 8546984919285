<template>
  <div class="widget-container">
    <div class="questionnaire-widget">
      <div class="widget-title row mb-3">
        <div class="col-sm-3">
          <h6>Title</h6>
        </div>
        <div class="col-sm-9">
          <input type="text" v-model="name" />
        </div>
      </div>
      <div class="questionnaire-toolbar">
        <a @click="onClick('input')"><i class="fas fa-font"></i> Text Input</a>
        <a @click="onClick('checkbox')"
          ><i class="far fa-check-square"></i> Checkbox</a
        >
        <a @click="onClick('dropdown')"
          ><i class="fas fa-caret-down"></i> Dropdown</a
        >
      </div>

      <b-card v-if="createCheckbox" class="mt-3">
        <input
          v-model="checkboxTitle"
          type="text"
          placeholder="Enter a name or question for this checkbox"
        />
        <hr />
        <div v-for="(option, index) in checkboxOptions" :key="index">
          <span
            title="Remove option"
            class="x-btn mr-2 mb-auto"
            @click="removeOption('checkboxOptions', index)"
          >
            <i class="fas fa-times"></i>
          </span>
          <input
            v-model="option.item"
            type="text"
            :placeholder="'Option #' + (index + 1)"
          />
        </div>
        <button class="btn-outline-blue mr-2" @click="removeCheckBox()">
          Cancel
        </button>
        <button class="btn-outline-blue" @click="addOption">
          Add an option
        </button>
        <button class="btn-blue ml-2" @click="addCheckbox">
          Submit options
        </button>
      </b-card>

      <b-card v-if="createInput" class="text-input mt-4 widget-title">
        <input
          type="text"
          v-model="inputTitle"
          placeholder="Add a title or message for your text input..."
        />
        <hr />
        <button class="btn-outline-blue mr-2 w-25" @click="removeInput()">
          Cancel
        </button>
        <button class="btn-blue w-25" @click="addInput">Add title</button>
      </b-card>

      <b-card v-if="createDropdown" class="mt-3">
        <input
          v-model="dropdownTitle"
          type="text"
          placeholder="Enter a name or question for this dropdown"
        />
        <hr />
        <div v-for="(item, index) in items" :key="index">
          <span
            title="Remove option"
            class="x-btn mr-2 mb-auto"
            @click="removeOption('items', index)"
          >
            <i class="fas fa-times"></i>
          </span>
          <input
            v-model="item.item"
            type="text"
            :placeholder="'Option #' + (index + 1)"
          />
        </div>
        <button class="btn-outline-blue mr-2" @click="removeDropdown()">
          Cancel
        </button>
        <button @click="addItem" class="btn-outline-blue">Add an item</button>
        <button @click="addDropdown" class="btn-blue ml-2">Submit items</button>
      </b-card>

      <span></span>

      <draggable
        group="contents"
        class="question-display m-2"
        v-if="content.length > 0"
        v-model="content"
      >
        <div v-for="(c, index) in content" :key="index" class="wiget-item">
          <div v-if="editingIndex === index && c.type === 'text'">
            <b-card class="text-input mt-4 widget-title">
              <input
                type="text"
                v-model="inputTitle"
                placeholder="Add a title or message for your text input..."
              />
              <hr />
              <button
                class="btn-outline-blue mr-2 w-25"
                @click="cancelItemEdit"
              >
                Cancel
              </button>
              <button class="btn-blue w-25" @click="saveItemEdit">Save</button>
            </b-card>
          </div>
          <div v-else-if="c.type == 'text'" class="container">
            <div class="row mb-4">
              <label class="col-12">
                <span
                  title="Remove item"
                  class="x-btn mr-2 mb-auto"
                  @click="removeItem(index)"
                >
                  <i class="fas fa-times"></i>
                </span>
                <span
                  title="Edit item"
                  class="x-btn mr-2 mb-auto"
                  @click="editItem(index, 'input')"
                >
                  <i class="fas fa-edit"></i>
                </span>
                <span style="border: none !important;">
                  {{ c.label }}</span
                ></label
              >
              <div class="col-12">
                <input
                  type="text"
                  class="form-control w-50 ml-13"
                  :placeholder="c.placeholder"
                  :name="name + '[' + c.label + ']'"
                />
              </div>
            </div>
          </div>

          <b-card
            v-if="editingIndex === index && c.type === 'checkbox'"
            class="mt-3"
          >
            <input
              v-model="checkboxTitle"
              type="text"
              placeholder="Enter a name or question for this checkbox"
            />
            <hr />
            <div v-for="(option, index) in checkboxOptions" :key="index">
              <span
                title="Remove option"
                class="x-btn mr-2 mb-auto"
                @click="removeOption('checkboxOptions', index)"
              >
                <i class="fas fa-times"></i>
              </span>
              <input
                v-model="option.value"
                type="text"
                :placeholder="'Option #' + (index + 1)"
              />
            </div>
            <button class="btn-outline-blue mr-2" @click="cancelItemEdit">
              Cancel
            </button>
            <button class="btn-outline-blue" @click="addOption">
              Add an option
            </button>
            <button class="btn-blue ml-2" @click="saveItemEdit">
              Save options
            </button>
          </b-card>
          <div v-else-if="c.type == 'checkbox'" class="container">
            <div class="row mb-4">
              <label class="col-12">
                <span
                  title="Remove item"
                  class="x-btn mr-2 mb-auto"
                  @click="removeItem(index)"
                >
                  <i class="fas fa-times"></i>
                </span>
                <span
                  title="Edit item"
                  class="x-btn mr-2 mb-auto"
                  @click="editItem(index, 'checkbox')"
                >
                  <i class="fas fa-edit"></i>
                </span>
                <span style="border: none !important;">
                  {{ c.label }}</span
                ></label
              >
              <div class="d-flex col-12">
                <div
                  v-for="option in c.options"
                  :key="option.id"
                  class="row ml-13"
                >
                  <label class="checkbox my-auto"> {{ option.text }}</label>
                  <input
                    type="checkbox"
                    class="my-auto"
                    :value="option.value"
                    :name="name + '[' + option.value + '][]'"
                  />
                </div>
              </div>
            </div>
          </div>

          <b-card
            v-if="editingIndex === index && c.type === 'dropdown'"
            class="mt-3"
          >
            <input
              v-model="dropdownTitle"
              type="text"
              placeholder="Enter a name or question for this dropdown"
            />
            <hr />
            <div v-for="(item, index) in items" :key="index">
              <span
                title="Remove option"
                class="x-btn mr-2 mb-auto"
                @click="removeOption('items', index)"
              >
                <i class="fas fa-times"></i>
              </span>
              <input
                v-model="item.value"
                type="text"
                :placeholder="'Option #' + (index + 1)"
              />
            </div>
            <button class="btn-outline-blue mr-2" @click="cancelItemEdit()">
              Cancel
            </button>
            <button @click="addItem" class="btn-outline-blue">
              Add an item
            </button>
            <button @click="saveItemEdit" class="btn-blue ml-2">
              Save items
            </button>
          </b-card>
          <div v-else-if="c.type == 'dropdown'" class="container">
            <div class="row mb-4">
              <label class="col-12">
                <span
                  title="Remove item"
                  class="x-btn mr-2 mb-auto"
                  @click="removeItem(index)"
                >
                  <i class="fas fa-times"></i>
                </span>
                <span
                  title="Edit item"
                  class="x-btn mr-2 mb-auto"
                  @click="editItem(index, 'dropdown')"
                >
                  <i class="fas fa-edit"></i>
                </span>
                <span style="border: none !important;">
                  {{ c.label }}</span
                ></label
              >
              <div class="col-12 d-flex">
                <b-form-select
                  size="sm"
                  :options="c.options"
                  v-model="c.value"
                  class="w-50 ml-13"
                  :name="name + '[' + c.label + ']'"
                ></b-form-select>
              </div>
            </div>
          </div>
        </div>
      </draggable>

      <div class="w-100 my-auto d-flex justify-content-around">
        <button class="btn-outline-blue w-25" @click.prevent="cancelEdit">
          Close
        </button>
        <button class="btn-blue w-25" @click.prevent="submitQuestionnaire">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import draggable from "vuedraggable";

export default {
  components: { draggable },
  props: ["widgetMeta"],
  data() {
    return {
      name: "",
      content: [],
      checkboxOptions: [],
      createCheckbox: false,
      checkboxTitle: "",
      createInput: false,
      inputTitle: "",
      createDropdown: false,
      dropdownTitle: "",
      items: [],
      addbutton: false,
      button: "",
      model: null,
      editingIndex: -1,
    };
  },
  mounted() {
    if (this.widgetMeta && typeof this.widgetMeta == "object") {
      if (this.widgetMeta.content) {
        for (let i in this.widgetMeta.content) {
          this.content.push(this.widgetMeta.content[i]);
        }
      }
      if (this.widgetMeta.name) {
        this.name = this.widgetMeta.name;
      }
    }
  },
  methods: {
    onClick(type) {
      this.cancelItemEdit();

      if (type == "checkbox") {
        this.createCheckbox = true;
      } else if (type == "input") {
        this.createInput = true;
      } else if (type == "dropdown") {
        this.createDropdown = true;
      }
    },
    addOption() {
      this.checkboxOptions.push({
        item: "",
      });
    },
    addItem() {
      this.items.push({
        item: "",
      });
    },
    editItem(index, type) {
      this.createCheckbox = false;
      this.createInput = false;
      this.createDropdown = false;
      this.cancelItemEdit();

      switch (type) {
        case "input":
          this.inputTitle = this.content[index].label;
          break;
        case "dropdown":
          this.items = this.content[index].options.slice(1).map((opt) => ({
            ...opt,
            text: opt.value,
          }));
          this.dropdownTitle = this.content[index].label;
          break;
        case "checkbox":
          this.checkboxTitle = this.content[index].label;
          this.checkboxOptions = this.content[index].options.map((opt) => ({
            ...opt,
            text: opt.value ?? opt.item,
          }));
          break;
      }

      this.editingIndex = index;
    },
    cancelItemEdit() {
      this.editingIndex = -1;
      this.dropdownTitle = "";
      this.inputTitle = "";
      this.checkboxTitle = "";
      this.checkboxOptions = [];
      this.items = [];
    },
    saveItemEdit() {
      const item = this.content[this.editingIndex];
      const type = item.type;

      switch (type) {
        case "text":
          item.label = this.inputTitle;
          break;
        case "dropdown":
          item.label = this.dropdownTitle;
          item.options = [
            { value: null, text: "Please select an option" },
          ].concat(
            this.items.map((item) => ({ value: item.value, text: item.value }))
          );
          break;
        case "checkbox":
          item.label = this.checkboxTitle;
          item.options = this.checkboxOptions.map((checkboxOption) => ({
            text: checkboxOption.value,
            value: checkboxOption.value,
          }));
          break;
      }

      this.cancelItemEdit();
    },
    removeItem(index) {
      const newItems = [...this.content];
      newItems.splice(index, 1);
      Vue.set(this, "content", newItems);
    },
    removeOption(options, index) {
      const newOptions = [...this[options]];
      newOptions.splice(index, 1);
      Vue.set(this, options, newOptions);
    },
    addCheckbox() {
      if (!this.checkboxTitle) {
        return this.$store.dispatch(
          "createErrors",
          "Please add a title for this checkbox"
        );
      }
      if (!this.checkboxOptions || this.checkboxOptions.length < 1) {
        return this.$store.dispatch("createErrors", "Please add an option");
      }

      let options = [];
      let errors = [];
      for (let i in this.checkboxOptions) {
        if (!this.checkboxOptions[i].item) {
          errors.push("Checkbox Option #" + (i + 1) + " needs a label");
          continue;
        }
        options.push({
          text: this.checkboxOptions[i].item,
          value: this.checkboxOptions[i].item,
        });
      }
      if (errors.length > 0) {
        return this.$store.dispatch("createErrors", errors);
      }
      this.content.push({
        type: "checkbox",
        label: this.checkboxTitle,
        options: options,
        value: [], //has to be an array
      });
      this.checkboxTitle = "";
      this.checkboxOptions = [];
      this.createCheckbox = false;
    },
    removeCheckBox() {
      this.checkboxTitle = "";
      this.checkboxOptions = [];
      this.createCheckbox = false;
    },
    addDropdown() {
      if (!this.dropdownTitle) {
        return this.$store.dispatch(
          "createErrors",
          "Please add a title for this dropdown"
        );
      }
      if (!this.items || !Array.isArray(this.items) || this.items.length < 1) {
        return this.$store.dispatch(
          "createErrors",
          "Please add an option to this dropdown"
        );
      }

      let options = [{ value: null, text: "Please select an option" }];
      for (let i in this.items) {
        options.push({ value: this.items[i].item, text: this.items[i].item });
      }
      this.content.push({
        type: "dropdown",
        label: this.dropdownTitle,
        options: options,
        value: null,
      });
      this.dropdownTitle = "";
      this.items = [];
      this.createDropdown = false;
    },
    removeDropdown() {
      this.dropdownTitle = "";
      this.items = [];
      this.createDropdown = false;
    },
    addInput() {
      if (!this.inputTitle) {
        return this.$store.dispatch(
          "createErrors",
          "Please add a title for this input"
        );
      }
      this.content.push({
        type: "text",
        label: this.inputTitle,
        placeholder: "Write something...",
        value: null,
      });
      this.inputTitle = "";
      this.createInput = false;
    },
    removeInput() {
      this.inputTitle = "";
      this.createInput = false;
    },
    submitQuestionnaire() {
      if (
        !this.content ||
        !Array.isArray(this.content) ||
        this.content.length == 0
      ) {
        return this.$store.dispatch(
          "createErrors",
          "Questionnaire requires at least one question."
        );
      }
      if (!this.name) {
        return this.$store.dispatch(
          "createErrors",
          "Questionnaire requires a title"
        );
      }
      const data = {
        content: this.content,
        type: "questionnaire",
        name: this.name,
      };
      this.$bus.$emit("widgetSaved", data);
    },
    cancelEdit() {
      this.$bus.$emit("clearWidget");
    },
  },
};
</script>
