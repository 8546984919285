<template>
  <div class="task-preview-window">
    <div class="task-preview mt-4 mb-4" v-for="(w, i) in widgets" :key="i">
      <div v-if="w.widget.data">
        <div v-if="w.widget.data.type == 'text'" class="widget-item">
          <div v-html="w.widget.data.content.html" class="text-widget"></div>
        </div>

        <div
          class="widget-item"
          v-else-if="w.widget.data.type == 'questionnaire'"
        >
          <div
            v-for="(c, index) in w.widget.data.content"
            :key="index"
            class="w-75 mx-auto"
          >
            <div v-if="c.type == 'text'" class="row mb-1">
              <label class="w-50">{{ c.label }}</label>
              <input
                type="text"
                :name="w.widget.data.name + '[' + c.label + ']'"
                class="form-control form-control-sm w-50"
                :placeholder="c.placeholder"
              />
            </div>

            <div v-if="c.type == 'checkbox'" class="row">
              <label class="w-50">{{ c.label }}</label>
              <div class="form-group w-50">
                <div
                  v-for="option in c.options"
                  :key="option.id"
                  class="form-check form-check-inline"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :name="w.widget.data.name + '[' + c.label + '][]'"
                    :value="option.value"
                  />
                  <label class="form-check-label">{{ option.text }}</label>
                </div>
              </div>
            </div>
            <div v-if="c.type == 'dropdown'" class="row mb-1">
              <label class="w-50">{{ c.label }}</label>
              <b-form-select
                size="sm"
                :name="w.widget.data.name + '[' + c.label + ']'"
                :options="c.options"
                :value="null"
                class="w-50"
              ></b-form-select>
            </div>
          </div>
        </div>

        <div class="widget-item" v-else-if="w.widget.data.type == 'video'">
          <vue-plyr>
            <div class="plyr__video-embed">
              <iframe
                :src="w.widget.data.content"
                allowfullscreen
                allowtransparency
                allow="autoplay"
              ></iframe>
            </div>
          </vue-plyr>
        </div>

        <div class="widget-item" v-else-if="w.widget.data.type == 'download'">
          <div class="download-widget">
            <p>{{ w.widget.data.content.message }}</p>
            <a
              class="back-btn"
              @click="
                downloadFile(
                  w.widget.data.content.url,
                  w.widget.data.content.fileName
                )
              "
              ><i class="fas fa-download"></i
              >{{ w.widget.data.content.fileName }}</a
            >
          </div>
        </div>

        <div class="widget-item" v-else-if="w.widget.data.type == 'photo'">
          <div class="photo-widget">
            <img
              :src="w.widget.data.content.url"
              :aria-alt="w.widget.data.content.text || 'Photo'"
            />
          </div>
        </div>
        <div class="widget-item" v-else-if="w.widget.data.type == 'delay'">
          <p class="delay-widget">
            <i class="far fa-clock"></i> This task will be sent
            {{ w.widget.data.content.substring("one") ? "in" : "on" }}
            {{ w.widget.data.content }}.
          </p>
        </div>

        <div class="widget-item" v-else-if="w.widget.data.type == 'upload'">
          <label :for="'file-upload-' + w.widget.data.name">{{
            w.widget.data.content && w.widget.data.content.message
              ? w.widget.data.content.message
              : "Upload File:"
          }}</label>
          <b-form-file
            :id="'file-upload-' + w.widget.data.name"
            :name="w.widget.data.name"
            size="sm"
            variant="light"
          ></b-form-file>
        </div>

        <div class="widget-item" v-else-if="w.widget.data.type == 'calendar'">
          <div class="calendar-widget">
            <a
              class="invite-link mb-2"
              :href="w.widget.data.content.url || '#'"
              target="_blank"
              ><i class="far fa-calendar-plus"></i>
              {{ w.widget.data.content.invite.title }}</a
            >
            <label class="mb-2"
              ><strong>Date: </strong>
              {{ convertDate(w.widget.data.content.invite.date) }}</label
            >
            <label class="mb-1"
              ><strong>From: </strong>
              {{ convertTime(w.widget.data.content.invite.time_start) }}
              <strong>To: </strong>
              {{
                convertDuration(
                  w.widget.data.content.invite.duration,
                  w.widget.data.content.invite.time_start
                ) || "No end time specified"
              }}</label
            >
            <label
              class="invitees mb-2"
              v-if="
                Array.isArray(w.widget.data.content.invite.invitees.length) &&
                  w.widget.data.content.invite.invitees.length > 0
              "
              ><strong class="mr-2">Invitees: </strong>
              {{ w.widget.data.content.invite.invitees.join(", ") }}</label
            >
            <label class="mb-2" v-if="w.widget.data.content.invite.message">{{
              w.widget.data.content.invite.message
            }}</label>
          </div>
        </div>

        <div v-else class="widget-item">
          {{ w.widget.data.content }}
          <hr />
        </div>
      </div>
      <div class="widget-item" v-if="!w.widget.data">
        {{ w.widget.title }}
        <p>This widget has not been edited</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment";
import VuePlayer from "../../VuePlayer";
Vue.use(VuePlayer);

export default {
  props: ["widgets"],
  data() {
    return {
      model: null,
    };
  },
  methods: {
    async downloadFile(url, label) {
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
          this.$bvToast.toast("Your file has been downloaded.", {
            title: "Success",
            variant: "info",
            autoHideDelay: 5000,
          });
        })
        .catch((e) => {
          this.$bvToast.toast(
            "There was an error downloading your file. Please ensure your file is an accepted file type.",
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
        });
    },
    convertDate(date) {
      if (date && moment(date, true).isValid()) {
        const strDate = moment(date).format("MMMM DD, YYYY");
        return strDate;
      }
      return date;
    },
    convertDuration(duration, time_start) {
      if (duration && time_start) {
        if (
          moment(time_start, "HH:mm:ss", true).isValid() ||
          moment(time_start, true).isValid()
        ) {
          let strTime = moment(time_start, "HH:mm:ss")
            .add(duration, "minutes")
            .format("h:mm A");
          return strTime;
        }
        return `${duration} minutes from ${time_start}`;
      }
    },
    convertTime(time) {
      if (
        time &&
        (moment(time, "HH:mm:ss", true).isValid() ||
          moment(time, true).isValid())
      ) {
        let strTime = moment(time, "HH:mm:ss").format("h:mm A");
        return strTime;
      }
      return time;
    },
  },
};
</script>

<style scoped>
.task-preview-window {
  background-color: white;
  height: 100%;
}
</style>
