<template>
  <div class="widget-container">
    <div class="form form-horizontal pl-1 pr-1">
      <div class="form-group mb-2">
        <div class="form-row">
          <label class="col-sm-4 text-left">
            Title:
          </label>
          <div class="col-sm-8">
            <input
              class="form-control form-control-sm"
              type="text"
              v-model="name"
            />
          </div>
        </div>
      </div>
      <div class="form-group mb-2">
        <div class="form-row">
          <label class="col-sm-4 text-left">
            Alternate Text:
          </label>
          <div class="col-sm-8">
            <input
              class="form-control form-control-sm"
              type="text"
              v-model="text"
            />
          </div>
        </div>
      </div>
      <div class="form-group mb-2">
        <div class="form-row">
          <label class="col-sm-4 text-left">
            Upload Photo:
          </label>
          <div class="col-sm-8">
            <b-form-file
              class="form-control form-control-sm"
              @change="onPhotoUpload($event)"
              accept="image/*"
              size="sm"
              id="file-small"
            ></b-form-file>
          </div>
        </div>
        <div class="form-row pt-2" v-if="photo.length > 0">
          <!-- <label>Preview:</label> -->
          <img :src="photo" class="mw-100 mx-auto h-auto" />
          <!-- <button class="btn-blue w-100 mt-4" @click="uploadPhoto">
            <p v-if="!submitting" class="m-0 p-0">Upload Photo</p>
            <div class="text-center" v-else>
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
          </button> -->
        </div>
      </div>
      <div class="w-100 mt-2 d-flex justify-content-around py-2">
        <button
          class="btn-outline-blue w-25"
          @click.prevent="cancelEdit()"
          v-if="widgetMeta != null"
        >
          Close
        </button>
        <button
          class="btn-blue w-25"
          @click.prevent="uploadPhoto"
          v-if="widgetMeta != null"
        >
          <p v-if="!submitting" class="m-0 p-0">Save</p>
          <div class="text-center" v-else>
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["widgetMeta"],
  data() {
    return {
      file: null,
      text: "",
      photo: [],
      name: "",
      submitting: false,
    };
  },
  mounted() {
    if (
      this.widgetMeta != null &&
      this.widgetMeta != [] &&
      this.widgetMeta.content != null
    ) {
      this.photo = this.widgetMeta.content.url;
      this.text = this.widgetMeta.content.text;
      this.name = this.widgetMeta.name;
    }
    this.getClientInContext;
  },
  computed: { ...mapGetters(["getClientInContext"]) },
  methods: {
    async onPhotoUpload(e) {
      const file = (await e.target.files[0]) || e.dataTransfer.files[0];
      this.file = file;
      const reader = new FileReader();

      reader.onload = (e) => {
        this.photo = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    async uploadPhoto() {
      if (this.submitting == (this.submitting = true)) {
        return false;
      }
      try {
        const data = {
          content: {},
          type: "photo",
          name: "Photo",
        };

        if (!this.file && !this.widgetMeta.content.url) {
          this.submitting = false;
          return this.$store.dispatch(
            "createErrors",
            "Please select a photo to upload."
          );
        }

        if (this.widgetMeta != null && this.widgetMeta.content != null) {
          data.content.fileName = this.widgetMeta.content.fileName || null;
          data.content.url = this.widgetMeta.content.url || null;
          data.name = this.widgetMeta.name;
        }

        let url = null;

        if (this.file != null) {
          const formData = new FormData();
          formData.append("file", this.file);
          formData.append("clientID", this.getClientInContext.client_id);
          formData.append("fileName", this.file.name.trim());
          if (this.name != "") {
            formData.append("widgetName", this.name.trim());
          }

          url = await this.$store.dispatch("uploadFile", {
            clientID: this.getClientInContext.client_id,
            formData: formData,
          });

          if (!url) {
            this.submitting = false;
            return this.$store.dispatch(
              "createErrors",
              "There was an issue uploading your photo, please try again."
            );
          }

          data.content.url = url;
        }

        if (this.text) {
          data.content.text = this.text;
        }

        if (this.name) {
          data.name = this.name;
        }
        this.$bus.$emit("widgetSaved", data);
      } finally {
        this.submitting = false;
      }
    },
    cancelEdit() {
      this.$bus.$emit("clearWidget");
    },
  },
};
</script>
